<template>
  <div class="video-container" v-if="data">
    <video loop ref="video" @click="videoClick" class="video" autoplay muted >
      <source :src="data.advertisementVideo" />
      您的浏览器不支持视频标签
    </video>

    <van-overlay :show="show">
      <div class="wrapper" @click="wrapperClick">
        <van-icon name="play-circle" color="#fff" size="18vw" />
      </div>
    </van-overlay>

    <div class="goods-info" v-if="!show">
      <div class="goods" @click="goodsClick">
        <img
          src="../../assets/maker/car-c.png"
          width="18"
          height="18"
          alt=""
          style="vertical-align: text-bottom; margin-right: 6px; color:white;"
        />
        购物 | 视频同款
      </div>
      <div class="store" v-if="data.videoType == '2'" >@{{data.nickName}}</div>
      <div class="store" v-else >@{{data.shopName}}</div>
      <div class="desc van-multi-ellipsis--l3">
        {{ data.advertisementCopywriting }}
      </div>
    </div>
  </div>
</template>

<script>
// import { openApp } from "@/common";
import { getVideo} from '../../api/makerAd/index.js'
export default {
  name: "makerAd",
  data() {
    return {
      show: true,
      playing: false,

      // data: {
      //   title: null,
      //   content: null,
      //   goodId: null,
      //   videoUrl: null,
      //   // "https://view.2amok.com/20200719/8d280f17b3ec29d6e91d06a38c02fa1f.mp4",
      //   // videoUrl: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-learning-vue/52d32740-aecd-11ea-b244-a9f5e5565f30.mp4",
      // },
      data: null
    };
  },
  created() {
  

    this.$store.commit('setSession', this.$route.query.session)
    getVideo(this.$route.query.id).then(res => { 
      this.data = res.data.data
      console.log(this.data);
      this.show = false;
      this.playing = true;
    //   console.log(document.querySelector('.video'));
    // document.querySelector('.video').play()
    // console.log(document.querySelector('.video'));
    })
  },
  methods: {
    videoClick() {
      this.playing ? this.$refs.video.pause() : this.$refs.video.play();
      this.playing = !this.playing;
    },
    wrapperClick() {
      this.show = false;
      this.playing = true;
      this.$refs.video.play();
    },
    goodsClick() {
      console.log(window.location.origin);
      this.openApp( '/#/detailsgoods?id=' + this.$route.query.id);
    },
  },
};
</script>

<style lang="less" scoped>
.video-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
  position: relative;
  video {
    width: 100%;
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .goods-info {
    position: absolute;
    left: 16px;
    bottom: 100px;
    width: 75vw;
    font-size: 14px;
    color: #fff;
    .goods {
      // display: flex;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 3px 6px;
      border-radius: 5px;
    }
    .store {
      font-size: 18px;
      margin: 12px 0 6px;
    }
  }
}
</style>